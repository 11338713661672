import whiteArrowRight from "../../../assets/imgs/whiteArrowRight.svg";

function Pricing() {
  return (
    <div className="flex flex-col items-center pb-10 mt-6 relative lg:pt-[2rem]">
      <div className="h-[50px] lg:h-[70px] w-full bg-[#D7E0F2] flex justify-center items-center absolute">
        <h3 className="headings mt-0 ">Pricing</h3>
      </div>
      <div className="h-[200px] w-[240px]  rounded-2xl relative flex justify-center lg:h-[300px] lg:w-[340px] mt-[2rem]">
        <div className="border border-black absolute h-[75%] w-full  bottom-0 rounded-xl px-2 bg-[#FFFBFB]">
          <p className="subText  px-2 leading-[18px] pt-1 lg:leading-[35px] font-semibold">
            Personalized guidance from experts
          </p>
          <p className="text-[12px]  px-2 leading-[18px] lg:text-[18px] lg:pb-[1rem]">
            (Orginal Price: <span className="line-through">9999</span>)
          </p>
          <p className="subText font-semibold  px-2 leading-[18px] pb-3 lg:pb-5">
            ₹ 2999{" "}
            <span className="text-[#10E826] font-normal">Flat 70% Off</span>
          </p>

          {/* Button */}
          <div className="flex justify-center">
            <button className="text-[12px] w-[90%] h-[24px] bg-lcsBlue rounded-2xl text-white shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[35px]">
              <span className="flex justify-center gap-2">
                Enroll Now{" "}
                <img
                  className="lg:h-[1.7rem]"
                  src={whiteArrowRight}
                  alt="whiteArrow"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
