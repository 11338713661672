import React from "react";

function Footer() {
  return (
    <div className=" ">
      <h3 className="headings  text-center px-3 pb-4 lg:pt-[3rem]">
        Ready to Take the First Step Toward Your Dream Career?
      </h3>
      <div className="flex justify-center pb-6 lg:pt-[2rem] lg:pb-[8rem]">
        <button className="bg-lcsBlue text-[12px] w-[50%] lg:w-[30%] h-[24px]  rounded-md text-white shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[50px]">
          Enroll Now
        </button>
      </div>
    </div>
  );
}

export default Footer;
