function CommunityGuidelines() {
  return (
    <div className="p-[1rem] lg:p-[1.5rem]">
      <h1 className="text-[1.2rem] font-bold lg:text-center lg:text-[1.5rem]">
        Community Guidelines for Posting and Commenting : Xampus
      </h1>

      <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
        Welcome to Our Community
      </h2>

      <p className="text-[13px]">
        We are excited to have you as part of our community! To maintain a safe,
        respectful, and inclusive environment, we have established the following
        community guidelines. By using this platform, you agree to adhere to
        these guidelines when posting content or commenting on others’ posts,
        regardless of whether you choose to display your name or post
        pseudonymously.
      </p>

      <hr className="mt-[1.3rem]" />

      {/* POSTING AND COMMENTING POLICIES DIV */}
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          Posting and Commenting Policies
        </h2>

        <h2 className="mt-[10px] text-[1rem] font-semibold">General Rules</h2>

        <p className="text-[13px]">
          <span className="font-semibold">1. Respect Others: </span>
          Treat all members of the community with respect. Harassment, hate
          speech, or abusive behavior will not be tolerated.
        </p>
        <p className="text-[13px]">
          <span className="font-semibold">2. No Harmful Content: </span>
          Do not post or comment any content that is violent, explicit,
          threatening, or promotes self-harm.
        </p>
        <p className="text-[13px]">
          <span className="font-semibold">3. No Illegal Activities: </span>
          Avoid posting or commenting anything that promotes or facilitates
          illegal activities.
        </p>
        <p className="text-[13px]">
          <span className="font-semibold">4. Protect Privacy: </span>
          Do not share private or sensitive information about yourself or
          others.
        </p>
        <p className="text-[13px]">
          <span className="font-semibold">5. No Misinformation: </span>
          Do not spread false or misleading information.
        </p>

        <h2 className="mt-[14px] text-[1rem] font-semibold">
          Pseudonymous Posting
        </h2>

        <p className="text-[13px]">
          We understand that pseudonymous posting allows users to express
          themselves freely. However, the following rules apply:
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">1. Accountability: </span>
          Even if you post or comment pseudonymously, you remain responsible for
          your content.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">2. Tracking Violations: </span>
          The platform retains the right to track and identify pseudonymous
          users if their content violates community guidelines or legal
          standards.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">3. Transparency: </span>
          By posting pseudonymously, you acknowledge that anonymity is
          conditional. Violating these guidelines may result in your identity
          being revealed to the necessary authorities.
        </p>

        <hr className="mt-[1.3rem]" />
      </div>

      {/* Reporting and Enforcement DIV */}
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          Reporting and Enforcement
        </h2>

        <h2 className="mt-[10px] text-[1rem] font-semibold">
          Reporting Violations
        </h2>

        <p className="text-[13px]">
          1. If you come across content that violates these guidelines, you can
          report it using the <span className="font-semibold">Report</span>{" "}
          button.
        </p>
        <p className="text-[13px]">
          2. Our moderation team will review reported content promptly and take
          appropriate action.
        </p>

        <h2 className="mt-[14px] text-[1rem] font-semibold">
          Consequences of Violations
        </h2>

        <p className="text-[13px]">
          <span className="font-semibold">1. Content Removal: </span>
          Posts or comments that violate guidelines will be removed.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">2. Account Actions: </span>
          Depending on the severity, your account may face restrictions,
          suspension, or permanent ban.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">3. Legal Actions: </span>
          In extreme cases, such as content involving illegal activities, we
          will cooperate with law enforcement.
        </p>

        <hr className="mt-[1.3rem]" />
      </div>

      {/* FAQs */}
      <div>
        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">FAQs</h2>

        <p className="text-[13px]">
          <span className="font-semibold">
            1. Can I remain anonymous forever when posting pseudonymously?{" "}
          </span>
          While we respect your preference for pseudonymity, it is conditional
          on your adherence to these guidelines. Posting harmful or illegal
          content may result in the platform identifying and taking action
          against you.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">
            2. What data is tracked for pseudonymous users?{" "}
          </span>
          The platform records information such as IP addresses, device IDs, and
          timestamps to ensure accountability. This information will only be
          used to address violations or comply with legal obligations.
        </p>

        <p className="text-[13px]">
          <span className="font-semibold">
            3. How can I avoid violating the guidelines?{" "}
          </span>
          Always ensure your content is respectful, accurate, and lawful. If in
          doubt, review these guidelines or refrain from posting.
        </p>

        <hr className="mt-[1.3rem]" />

        {/* COMMITMENT TO THE COMMUNITY */}

        <h2 className="mt-[1.4rem] text-[1.1rem] font-semibold">
          Commitment to the Community
        </h2>

        <p className="text-[13px]">
          We strive to create a platform where everyone feels safe and heard.
          Your participation is crucial in maintaining this environment. If you
          have any questions or suggestions about these guidelines, feel free to
          contact us at{" "}
          <a className="text-[#243c5a]" href="developer@xampus.tech">
            developer@xampus.tech
          </a>{" "}
          <br />
          Thank you for being part of our community!
        </p>
      </div>
      <hr className="mt-[1.3rem]" />
    </div>
  );
}

export default CommunityGuidelines;
