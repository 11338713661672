import tailored from "../../../assets/imgs/tailored.svg";
import expert from "../../../assets/imgs/expert.svg";
import jobOpp from "../../../assets/imgs/jobOpp.svg";
import skillDev from "../../../assets/imgs/skillDev.svg";
import oneOnOne from "../../../assets/imgs/oneOnOne.svg";

function WhyChoose() {
  const data = [
    {
      svg: tailored,
      heading: "Tailored Roadmaps:",
      para: "Clear steps based on your career goals.",
    },
    {
      svg: expert,
      heading: "Expert Advice:",
      para: "Insights from seasoned professionals.",
    },
    {
      svg: jobOpp,
      heading: "Job Opportunities:",
      para: "Access exclusive listings.",
    },
    {
      svg: skillDev,
      heading: "Skill Development:",
      para: "Suggestions for upskilling.",
    },
    {
      svg: oneOnOne,
      heading: "One-on-One Sessions:",
      para: "Personalized mentoring.",
    },
  ];

  return (
    <div className="border mb-6 pl-1">
      <h3 className="headings text-center px-[1rem] mt-4 lg:pt-[2rem]">
        Why Choose Our Career Guidance Program?
      </h3>

      <div className="flex flex-col gap-3 px-[10px] pt-[20px] pb-[40px] lg:px-[6rem] lg:mt-[2rem]">
        {data.map((e, index) => (
          <div
            key={index}
            className="border flex text-[10px] p-[3px] gap-[4px] lg:text-[20px] lg:p-[1rem] py-[6px]"
          >
            <img className="lg:h-[2rem]" src={e.svg} alt="icon" />

            <span className="pt-[2px] font-semibold">{e.heading}</span>
            <span className="pt-[2px]">{e.para}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WhyChoose;
