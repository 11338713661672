import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import blueLogo from "../assets/imgs/xlogoBlue.png";
import { useAuth } from "../contexts/firebaseContext/Context";
import LogoutModal from "./LogoutModal";

interface Props {
  styles: string;
}

const Navbar = ({ styles }: Props) => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { currentUser } = useAuth();
  const [isScrolled, setIsScrolled] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (location.pathname == "/account-deletion") {
    return (
      <nav
        className={`sticky top-0 ${styles} py-[0.5rem] px-[2rem] md:py-[1rem] lg:px-[3rem] transition ease-in z-50 ${
          isScrolled ? "scrolled" : "notScrolled"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <a href="/">
              <img
                src={blueLogo}
                className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                alt="Logo"
              />
            </a>
          </div>

          <div className="flex items-center gap-3 md:gap-[2rem] lg:gap-[3rem] py-3">
            <NavLink
              to="/"
              className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                isScrolled ? "navScrollMenu" : "navMenu"
              }`}
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                isScrolled ? "navScrollMenu" : "navMenu"
              }`}
            >
              About
            </NavLink>

            {currentUser ? (
              <>
                <button
                  onClick={openModal}
                  className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                    isScrolled ? "navScrollMenu" : "navMenu"
                  }`}
                >
                  Logout
                </button>
                <LogoutModal isOpen={isModalOpen} onClose={closeModal} />
              </>
            ) : (
              <>
                <NavLink
                  to="/user/login"
                  className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                    isScrolled ? "navScrollMenu" : "navMenu"
                  }`}
                >
                  Login
                </NavLink>
                <NavLink
                  to="/user/register"
                  className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                    isScrolled ? "navScrollMenu" : "navMenu"
                  }`}
                >
                  Register
                </NavLink>
              </>
            )}
          </div>
        </div>
      </nav>
    );
  } else {
    return (
      <nav
        className={`sticky top-0 ${styles} py-[0.5rem] px-[2rem] md:py-[1rem] lg:px-[3rem] transition ease-in z-50 ${
          isScrolled ? "scrolled" : "notScrolled"
        }`}
      >
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <a href="/">
              <img
                src={blueLogo}
                className="h-[2rem] md:h-[3rem] rounded-md shadow-custom"
                alt="Logo"
              />
            </a>
          </div>

          <div className="flex items-center gap-3 md:gap-[2rem] lg:gap-[3rem] py-3">
            <NavLink
              to="/"
              className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                isScrolled ? "navScrollMenu" : "navMenu"
              }`}
            >
              Home
            </NavLink>
            <NavLink
              to="/about-us"
              className={`text-[1rem] md:text-[1.3rem] tracking-wide font-semibold navLink ${
                isScrolled ? "navScrollMenu" : "navMenu"
              }`}
            >
              About
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
