import React from "react";
import Button from "../../../components/resume-services-v3/Button/Button";
import winImg from "../../../assets/imgs/winImg.svg";
import computer2 from "../../../assets/imgs/computer2.svg";
import Pricing from "./Pricing";
import WhyChoose from "./WhyChoose";
import LimitedTimeOffer from "./LimitedTimeOffer";
import HappyClients from "./HappyClients";
import Footer from "./Footer";

function Guidance2() {
  return (
    <div className="overflow-x-hidden">
      <section className="relative mt-4 my-10 lg:h-[90vh] pl-1">
        <div className="px-3 pt-[1rem] pr-[6rem] lg:pl-[7rem] lg:pt-[11rem]">
          <h3 className="headings pr-[4rem]">
            Your Path to Success Starts Here!
          </h3>
          <p className="text-[10px] leading-[15px] lg:text-[20px] lg:leading-[40px] lg:pr-[45%] pr-[20%]">
            Transform your career with personalized guidance from experts.
            Limited-time offer!
          </p>
          <p className="text-[10px] leading-[15px] pb-3 lg:text-[20px] lg:leading-[40px] lg:pb-[5%]">
            Unlock Your Career Growth Today!
          </p>
          <Button
            height="h-7"
            width="lg:w-[30%] w-[70%]"
            label="Enroll Now"
            labelSize="text-[14px] lg:text-[20px]"
            onClick={() => console.log("Hello World")}
          />
          <img
            className="absolute bottom-8 right-0 lg:h-[70%] lg:bottom-20 lg:right-2"
            src={winImg}
            alt="backgroungSvg"
          />
        </div>
      </section>

      {/* What You'll Get */}

      <section className="bg-[#CEE8F7] bg-opacity-[60%] py-[1.5rem] flex lg:gap-[10rem] lg:justify-center">
        <img
          className="h-[6rem] mt-7 lg:h-[18rem] lg:mt-0"
          src={computer2}
          alt="computer"
        />
        <div className="lg:pl-[3rem]">
          <h3 className="headings m-0 pb-2 lg:pl-[2rem] lg:pb-[30px] lg:pt-[50px]">
            What You'll Get in the Program
          </h3>
          <ul className="list-disc pl-[10px] text-[11px] gap-3 space-y-2 lg:text-[20px] lg:pl-[4rem] lg:space-y-4">
            <li>5 Career Counseling Sessions</li>
            <li>Access to Career Resources and Tools</li>
            <li>Resume and LinkedIn Profile Review</li>
            <li>Lifetime Access to Program Materials</li>
          </ul>
        </div>
      </section>

      <Pricing />
      <WhyChoose />
      <LimitedTimeOffer />
      <HappyClients />
      <Footer />
    </div>
  );
}

export default Guidance2;
