import computer2 from "../../../assets/imgs/oldLaptop.svg";
import todo from "../../../assets/imgs/todo.svg";
import successStairs from "../../../assets/imgs/successStairs.svg";
import Pricing from "./Pricing";
import WhyChoose from "./WhyChoose";
import LimitedTimeOffer from "./LimitedTimeOffer";
import HappyClients from "./HappyClients";
import Footer from "./Footer";

function Guidance3() {
  return (
    <div className="overflow-x-hidden">
      <section className="relative mt-4 my-10 lg:h-[90vh] pl-1 lg:pl-4">
        <div className="px-3 pt-[1rem] pl-[8.5rem]  lg:pl-[25rem] lg:pt-[11rem] text-center ">
          <h3 className="headings">Your Path to Success Starts Here!</h3>
          <p
            className="text-[10px] text-shadow-lg leading-[15px] lg:text-[20px] lg:leading-[40px]"
            style={{ textShadow: "2px 2px 7px rgba(0, 0, 0, 0.3)" }}
          >
            Transform your career with personalized guidance from experts.
            <br />
            Limited-time offer!
          </p>
          <p
            className="text-[10px] leading-[15px] pb-3 lg:text-[20px] lg:leading-[40px] lg:pb-[5%]"
            style={{ textShadow: "2px 2px 7px rgba(0, 0, 0, 0.3)" }}
          >
            Unlock Your Career Growth Today!
          </p>
          {/* Button */}
          <div className="flex justify-center ">
            <button className="text-[12px] w-[70%] lg:w-[30%] h-[24px] bg-lcsBlue rounded-md text-white shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[45px]">
              Enroll Now
            </button>
          </div>
          <img
            className="absolute h-[65%] bottom-8 left-0 lg:left-8 lg:h-[70%] lg:bottom-20 lg:right-2"
            src={successStairs}
            alt="backgroungSvg"
          />
        </div>
      </section>

      {/* What You'll Get */}

      <section className="py-[1.5rem] flex lg:gap-[10rem] lg:justify-center relative">
        <img
          className="h-[4rem] mx-2 mr-4 mt-[4.5rem] lg:h-[14rem] lg:mt-0 lg:mr-[70%]"
          src={computer2}
          alt="computer"
        />
        <div className="lg:pl-[25%] mt-[1rem] absolute left-[90px] lg:-mt-[3rem]">
          <h3 className="headings m-0 pb-2 lg:pl-[2rem] lg:pb-[30px] lg:pt-[50px] ">
            What You'll Get in the Program
          </h3>
          <ul className="list-disc pl-[10px] text-[11px] gap-3 space-y-2 lg:text-[20px] lg:pl-[4rem] lg:space-y-4">
            <li>5 Career Counseling Sessions</li>
            <li>Access to Career Resources and Tools</li>
            <li>Resume and LinkedIn Profile Review</li>
            <li>Lifetime Access to Program Materials</li>
          </ul>
        </div>
        <img
          className="h-[2.3rem] mx-2 mr-4 lg:h-[7rem]  absolute right-0 top-20 lg:right-[10%] lg:top-4"
          src={todo}
          alt="todoList"
        />
      </section>

      <Pricing />
      <WhyChoose />
      <LimitedTimeOffer />
      <HappyClients />
      <Footer />
    </div>
  );
}

export default Guidance3;
