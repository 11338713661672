import blueStar from "../../../assets/imgs/filledGrayStar.svg";
import blurStar from "../../../assets/imgs/unfilledGrayStar.svg";

interface CardProps {
  imgSrc?: string;
  name?: string;
  review?: string;
  // stars?: number;
}

function ReviewCard({ review, imgSrc, name }: CardProps) {
  return (
    <div className="relative h-[8.3rem] lg:h-[22rem]  w-[7rem] lg:w-[17rem] rounded-md">
      <div className="absolute pl-[34px] lg:pl-[70px] bg-white rounded-md z-10 ">
        <p className="text-[7px] leading-[10px] lg:text-[1rem] lg:leading-[30px]">
          {review}
        </p>
        <p className="text-[8px] lg:text-[1.2rem] mt-0 lg:my-3">{name}</p>
      </div>

      <img
        className="absolute h-[2rem] lg:bottom-[70px] lg:h-[4rem] bottom-1 -left-1 z-20"
        src={imgSrc}
        alt="reviewerImg"
      />

      <div className="bg-[#37474F] absolute -bottom-[3px] lg:bottom-[62px] h-[15%] w-full rounded-md z-0"></div>
    </div>
  );
}

export default ReviewCard;
