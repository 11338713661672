import React from "react";
import newLaptop from "../../../assets/imgs/newLaptop.svg";
import check from "../../../assets/imgs/check.svg";

function WhyChoose() {
  const data = [
    {
      heading: "Tailored Roadmaps:",
      para: "Clear steps based on your career goals.",
    },
    {
      heading: "Expert Advice:",
      para: "Insights from seasoned professionals.",
    },
    {
      heading: "Job Opportunities:",
      para: "Access exclusive listings.",
    },
    {
      heading: "Skill Development:",
      para: "Suggestions for upskilling.",
    },
    {
      heading: "One-on-One Sessions:",
      para: "Personalized mentoring.",
    },
  ];

  return (
    <div className="flex relative mt-[4rem] lg:h-[60vh] lg:justify-center">
      <h3 className="left-5 absolute headings top-[-80px] lg:left-1/2 lg:-translate-x-1/2 lg:top-[-50px]">
        Why Choose Our Career Guidance Program?
      </h3>

      <div className="pl-3 lg:pl-0 flex flex-col lg:pt-[1rem]">
        <ul className="pl-[2px] pt-[20px] pb-[40px] lg:px-[2rem] lg:mt-[2rem]">
          {data.map((e, index) => (
            <div
              key={index}
              className="flex text-[10px] p-[3px] gap-[4px] lg:text-[20px] lg:p-[1rem] py-[6px]"
            >
              <img
                className="mr-1 lg:h-[1rem] lg:mt-3"
                src={check}
                alt="blueTick"
              />
              <li className="pt-[2px] font-semibold">
                {e.heading}
                <span className="font-normal pl-1">{e.para}</span>
              </li>
            </div>
          ))}
        </ul>
      </div>

      <img
        className="h-[6rem] mt-10 lg:h-[22rem] lg:pl-[9rem]"
        src={newLaptop}
        alt="newLaptopImg"
      />
    </div>
  );
}

export default WhyChoose;
