import ReviewCard from "./ReviewCard";
import pic1 from "../../../assets/imgs/arshNewSmall.png";
import pic2 from "../../../assets/imgs/rishiNew.png";
import pic3 from "../../../assets/imgs/nupurNew.png";

function HappyClients() {
  return (
    <div className="mb-[2rem] lg:mb-[3rem] px-3">
      <h3 className="headings text-center pb-[30px] lg:pt-[4rem]">
        Hear From Our Happy Clients!
      </h3>

      <div className="flex gap-4 justify-center lg:gap-[10rem] lg:pt-[3rem]">
        <ReviewCard
          review={`"The step-by-step guidance and practical advice I received were invaluable. This program equipped me with the skills and confidence to land my dream job!"`}
          stars={5}
          imgSrc={pic1}
          name="ARSHDEEP S"
        />
        <ReviewCard
          review={`"The program's expert support in refining my resume and sharpening my interview skills was invaluable. I’ve secured a role that surpasses my expectations."`}
          stars={4}
          imgSrc={pic2}
          name="RISHIKESH"
        />
        <ReviewCard
          review={`"The hands-on mentorship and personalized strategies made all the difference. I feel more prepared and confident in navigating my career journey."`}
          stars={4}
          imgSrc={pic3}
          name="NUPUR P"
        />
      </div>

      <hr className="mt-[2rem] border-[#CEE8F7] " />
    </div>
  );
}

export default HappyClients;
