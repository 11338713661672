function Pricing() {
  return (
    <div className="flex justify-center pb-10">
      <div className="border border-lcsBlue h-[200px] w-[240px] mt-8 rounded-2xl relative flex justify-center lg:h-[300px] lg:w-[340px]">
        <h3 className="headings m-0 text-lcsBlue mt-3 lg:mt-6">Pricing</h3>
        <div className="absolute h-[75%] w-full bg-lcsBlue bottom-0 rounded-xl px-2">
          <p className="subText text-white px-2 leading-[18px] pt-1 lg:leading-[35px]">
            Personalized guidance from experts
          </p>
          <p className="text-[12px] text-white px-2 leading-[18px] lg:text-[18px] lg:pb-[1rem]">
            (Orginal Price: <span className="line-through">9999</span>)
          </p>
          <p className="subText font-semibold text-white px-2 leading-[18px] pb-3 lg:pb-5">
            ₹ 2999{" "}
            <span className="text-[#10E826] font-normal">Flat 70% Off</span>
          </p>

          {/* Button */}
          <div className="flex justify-center">
            <button className="text-[12px] w-[60%] h-[24px] bg-white rounded-md text-lcsBlue shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[35px]">
              Enroll Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
