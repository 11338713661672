import { useRef } from "react";
import SplitText from "../components/SplitText";
import Navbar from "../components/Navbar";
import left from "../assets/imgs/topLeftImg.png";
import center from "../assets/imgs/topCenter1.png";
import right from "../assets/imgs/topRightImg.png";
// import rightNew from "../assets/imgs/rightnew-2.png";
import AndroidQrCode1 from "../assets/imgs/qr.png";
import PlayStoreIcon from "../assets/imgs/google-play 1.png";
import AppleLogo from "../assets/imgs/AppleLogo.png";
import MobImg1 from "../assets/imgs/bottomLeft.png";
import MobImg2 from "../assets/imgs/bottomCenter.png";
import MobImg3 from "../assets/imgs/bottomRightNew.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { Helmet } from "react-helmet";
import RedesignedFooter from "../components/RedesignedFooter";

const RedesignedHome = () => {
  const missionRef = useRef<HTMLDivElement>(null);
  const visionRef = useRef<HTMLDivElement>(null);
  const triggerRef1 = useRef<HTMLDivElement>(null);

  gsap.registerPlugin(ScrollTrigger);

  useGSAP(() => {
    gsap.from(missionRef.current, {
      opacity: 0,
      y: -300,
      duration: 1.4,
      scrollTrigger: {
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      },
    });
    gsap.from(visionRef.current, {
      opacity: 0,
      y: 300,
      duration: 1.4,
      scrollTrigger: {
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      },
    });
    // gsap.from(cardRef.current,{
    //   opacity:0,
    //   y:-120,
    //   duration:1.8,
    //   scrollTrigger:{
    //     trigger: triggerRef2.current,
    //     scroller: "body",
    //     start: "top 80%",
    //   }
    // })
  });

  return (
    <>
      <Helmet>
        <title>Xampus - AI Enabled Professional Network</title>
        <meta
          name="description"
          content="Unlock your full professional potential with our tips! Discover how AI can transform your job search experience and lead you to outstanding career opportunities. Don’t miss your chance to grow!"
        />
        <meta name="keywords" content="xampus, home page, jobs, career" />
      </Helmet>
      <Navbar styles="" />
      {/* hero section */}
      <div className="relative text-center   h-[31rem] md:h-[50rem] lg:h-[60rem]">
        <div className="text-center inline-block mt-[2rem] md:mt-[2rem]">
          <h1 className="lg:text-[5.6rem] text-[2rem] font-bold md:text-[4rem]">
            <SplitText text="Xampus" />
          </h1>
        </div>

        <h1 className="heading text-[#030A74] lg:text-[1.9rem] text-[1rem] font-bold md:text-[1.6rem]">
          <span className="text-yellow-600">Jobs, </span>Network and AI
        </h1>

        <div className="left-img-div">
          <img
            src={left}
            alt=""
            loading="lazy"
            className="left left-img lg:h-[38rem] h-[16rem] -mb-1 md:-mb-0 md:h-[32rem] lg:mr-[1.5rem]"
          />
          {/*  */}
        </div>

        <div className="video-div z-40">
          <img
            src={center}
            alt=""
            loading="lazy"
            className=" w-40  md:w-80 md:h-[40rem] lg:w-[21.6rem]  lg:h-[44.8rem] -mb-5 lg:-mb-7"
          />
        </div>

        <div className="right-img-div">
          {/* <img src={rightNew} alt="" loading="lazy" className="right right-img lg:h-[43.8rem] h-[18.8rem] md:h-[36rem] -mb-5 lg:-mb-10" /> */}
          <img
            src={right}
            alt=""
            loading="lazy"
            className="right right-img lg:h-[38rem] h-[16rem] -mb-1 md:-mb-0 md:h-[32rem] lg:ml-[1.5rem]"
          />
        </div>
      </div>

      {/* checkout xampus app section */}

      <div className="bg-white w-full mt-56 flex justify-center items-center">
        <div className="lg:w-[25vw]  flex flex-col justify-center items-center gap-3">
          <h1 className="text-2xl font-bold">Checkout our Xampus App</h1>
          <h6 className="text-center">
            Connect, collaborate, and cultivate your career with like-minded
            professionals across diverse industries.
          </h6>
          <div className="flex flex-col justify-center items-center gap-6 ">
            <img
              className="lg:w-[200px] lg:h-[200px] rounded-lg w-[130px] h-[130px]"
              src={AndroidQrCode1}
              alt=""
            />
            <button
              className="flex justify-center items-center bg-black text-white px-3 py-1 rounded-lg"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.xampus.ai.app",
                  "_blank"
                )
              }
            >
              <img src={PlayStoreIcon} alt="Play store logo" className="mr-2" />
              Play Store
            </button>

            <span className="flex items-center">
              For{" "}
              <img className="h-[15px] px-1" src={AppleLogo} alt="Apple logo" />{" "}
              Comming Soon
            </span>
          </div>
        </div>
      </div>

      {/* for 3rd setion your future powered ai */}
      <div
        className=" mt-32  w-full pb-10"
        style={{
          background:
            "linear-gradient(180deg, #FFFFFF 0.6%, #E0E6FF 44.6%, #060D75 92.6%)",
        }}
      >
        <div className="w-full mt-10 flex justify-center items-center flex-col gap-10 px-32">
          <h1 className="text-3xl   font-bold text-center text-[#060D75] ">
            A new way to connect, collaborate,
            <br className="hidden md:flex" /> and grow —powered by AI
          </h1>
          <div className="flex  justify-center items-center w-full">
            <div className="grid grid-cols-1 md:-mt-10 md:grid-cols-3 gap-10 w-full">
              <div className="flex flex-col justify-center items-center gap-3">
                <h1 className="text-lg font-bold text-center text-[#060D75]">
                  "Explore Ideas. Share <br className="hidden md:flex" />
                  Experiences. Stay Anonymous."
                </h1>
                <img
                  className="w-[300px]"
                  src={MobImg1}
                  alt="xampus_features_photos"
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col justify-center items-center gap-3 md:mt-32">
                <h1 className="text-lg font-bold text-center text-[#060D75]">
                  "Find Your Dream Job, <br className="hidden md:flex" /> &nbsp;
                  Faster and Easier."
                </h1>
                <img
                  className="w-[300px]"
                  src={MobImg2}
                  alt="xampus_features_photos"
                  loading="lazy"
                />
              </div>
              <div className="flex flex-col justify-center items-center gap-3">
                <h1 className="text-lg font-bold text-center  text-[#060D75]">
                  "Connect & Sync: Where
                  <br className="hidden md:flex" /> Your Social World Aligns”
                </h1>
                <img
                  className="w-[300px]"
                  src={MobImg3}
                  alt="xampus_features_photos"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <RedesignedFooter />
    </>
  );
};

export default RedesignedHome;
