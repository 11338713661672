import userImg from "../../assets/imgs/userImg.png";
import userQr from "../../assets/imgs/userQr.svg";
import experienceSvg from "../../assets/imgs/experienceSvg.svg";
import mobileImg from "../../assets/imgs/mobileImg.png";
import iphoneMockup from "../../assets/imgs/iphoneMockup.png";
import xlogo from "../../assets/imgs/xlogoBlue.png";

function UserPage() {
  const userData = {
    userName: "Jay Singh",
    userImg: userImg,
    role: "Mobile App Tester",
    location: "Bangalore",
    postsCount: 5,
    followers: 132,
    following: 76,
    userQr: userQr,
    aboutDetails: `Hi, I’m Jay Singh a Mobile App Tester, with 5+
  years of experience in the field currently working
  at Xampus as a Mobile App Tester. My Job at 
  X...more`,
  };

  return (
    <div
      className="flex items-center justify-center min-h-screen bg-gray-100 "
      style={{
        position: "relative",
      }}
    >
      <div
        className="p-6 pt-[3.6rem] relative"
        style={{
          backgroundImage: `url(${iphoneMockup})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          height: "700px",
          width: "350px",
          overflow: "hidden",
        }}
      >
        {/* Scrollable content wrapper */}
        <div
          className="overflow-y-auto overflow-x-hidden"
          style={{
            height: "100%", // Make it take up the full height of the mockup
          }}
        >
          <div
            className="absolute w-[312px] h-[63px] bottom-[10px] left-[1rem]  bg-gradient-to-r from-blue-500 to-blue-700"
            style={{
              borderBottomLeftRadius: "40px",
              borderBottomRightRadius: "40px",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <button
              className="w-[100%] h-full text-white font-semibold py-2 hover:opacity-90"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.xampus.ai.app",
                  "_blank"
                )
              }
            >
              Explore Full Profile
            </button>
          </div>

          <div className="overflow-x-hidden">
            <section className="mt-[1rem] my-4 flex gap-[1rem] ml-[1rem]">
              <div className="border-[#8C8A93] border h-[40px] w-[40px] rounded-xl flex p-2">
                <img src={xlogo} alt="xampusLogo" />
              </div>
              <h6 className="text-[15px] text-customBlue font-semibold mt-[7px]">
                Xampus
              </h6>
            </section>

            <hr className="border-[0.9px] w-[98%] " />

            <section className="mt-[1.1rem] my-4 ml-2">
              <div className="flex gap-4">
                <div className="w-[65px]">
                  <img src={userData.userImg} alt="userImg" />
                </div>

                <div className="mt-[-2px]">
                  <p className="text-[18px] font-semibold leading-[17px]">
                    {userData.userName}
                  </p>
                  <p className="text-[12px] mt-0">{userData.role}</p>
                  <p className="text-[12px] text-gray-600 font-opacity-[70%] mt-[-10px]">
                    {userData.location}
                  </p>
                </div>
              </div>

              <div className="flex ml-[4.3rem] mt-2 gap-[1.5rem]">
                <div className="h-[44px] w-[44px] flex flex-col gap-0 items-center justify-center">
                  <p className="text-[11px] font-semibold mt-0">
                    {userData.postsCount}
                  </p>
                  <p className="text-[11px]  mt-[-9px]">Posts</p>
                </div>
                <div className="h-[44px] w-[44px] flex flex-col gap-0 items-center justify-center">
                  <p className="text-[11px] font-semibold mt-0">
                    {userData.followers}
                  </p>
                  <p className="text-[11px]  mt-[-9px]">Followers</p>
                </div>
                <div className="h-[44px] w-[44px] flex flex-col gap-0 items-center justify-center">
                  <p className="text-[11px] font-semibold mt-0">
                    {userData.following}
                  </p>
                  <p className="text-[11px]  mt-[-9px]">Following</p>
                </div>
              </div>
            </section>

            {/* FOLLOW FOLLOWING BUTTONS */}
            <section className="mt-[1rem] my-4  flex gap-3 ml-5">
              <button className="w-[100px] h-[45px] bg-gradient-to-r from-[#139AFD] to-[#436AF4] text-white font-semibold px-4 rounded-lg shadow-md hover:opacity-90">
                <span className="text-[13px]">Follow</span>
              </button>
              <button className="w-[100px] h-[45px] bg-gradient-to-r from-[#139AFD] to-[#436AF4] text-white font-semibold px-4 rounded-lg shadow-md hover:opacity-90">
                <span className="text-[13px]">Messages</span>
              </button>

              <div className="w-[45px] h-[30px]">
                <img src={userData.userQr} alt="userQrCode" />
              </div>
            </section>

            {/* ABOUT SECTION */}

            <section className="pl-2 pr-2 pt-4">
              <h6 className="text-[15px] text-customBlue mt-[5px]">About</h6>
              <p className="text-[12px] leading-[23px]">
                {userData.aboutDetails}
              </p>
            </section>

            <hr className="border-2 mt-6 w-[98%] " />

            {/* OFFEREINGS */}

            <section className="pl-2 pr-3 pt-4">
              <h6 className="text-[15px] text-customBlue mt-[5px]">
                Offerings
              </h6>
              <h6 className="text-[15px] mt-[5px]">Mobile App Testing</h6>
              <p className="text-[12px] leading-[23px]">
                Complete Mobile App Testing and Comprehensive study of App with
                sheets of bug and issues.
              </p>
              <p className="text-[15px] text-customBlue text-right">
                20K/Project
              </p>

              <hr className="border-[0.9px] mt-3" />

              <h6 className="text-[15px] mt-[15px]">
                Website Performance Testing
              </h6>
              <p className="text-[12px] leading-[23px]">
                Overall Web performance testing
              </p>
              <p className="text-[15px] text-customBlue text-right">
                2-5K/Project
              </p>
            </section>

            <hr className="border-2 mt-6 w-[98%] " />

            {/* Experience */}

            <section className="pl-2 pr-3 pt-4">
              <h6 className="text-[15px] text-customBlue mt-[5px]">
                Experience
              </h6>

              <div className="flex gap-[1rem] mt-3">
                <img src={experienceSvg} alt="experienceSvg" />
                <div>
                  <h4 className="text-[15px] font-semibold">Xampus</h4>
                  <p className="text-[12px] mt-0">
                    App Tester {"  "} Internship {"  "} Remote
                  </p>
                  <p className="text-[12px] mt-0 mt-[-5px]">
                    June 2024 Present
                  </p>
                </div>
              </div>
            </section>

            <hr className="border-2 mt-6 w-[98%] " />

            <section className="pl-2 pr-3 pt-4 pb-4">
              <h6 className="text-[15px] text-customBlue mt-[5px]">
                Portfolio
              </h6>

              <div className="flex">
                <div>
                  <h6 className="text-[15px] mt-[5px] font-semibold">
                    App Testing Sheets
                  </h6>
                  <p className="text-[12px]">
                    These is my previous work which i have done for various apps
                    testings.
                  </p>

                  <div className="pt-3">
                    <button className="w-[83px] h-[33px] p-1 border border-gray-500 rounded text-[12px] text-customBlue">
                      Visit Link
                    </button>
                  </div>
                </div>

                <div className="h-[150px] w-[130px] mt-3">
                  <img src={mobileImg} alt="mobileImg" />
                </div>
              </div>
            </section>

            <hr className="border-2 mt-2 w-[98%] " />

            {/* EDUCATION */}
            <section className="pl-2 pr-3 pt-4">
              <h6 className="text-[15px] text-customBlue mt-[5px]">
                Education
              </h6>

              <div className="flex gap-[1rem] mt-3">
                <img src={experienceSvg} alt="experienceSvg" />
                <div>
                  <h4 className="text-[15px] font-semibold">
                    Delhi University
                  </h4>
                  <p className="text-[12px] mt-0">Bachelor of Arts Delhi</p>
                  <p className="text-[12px] mt-0 mt-[-5px]">
                    May 2018 April 2022
                  </p>
                </div>
              </div>
            </section>

            <hr className="border-2 mt-6 w-[98%] " />

            <div className="mb-[6rem]"></div>
            {/* The rest of your content remains unchanged */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPage;
