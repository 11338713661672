import React from "react";
import checking from "../../../assets/imgs/checking.svg";

function WhyChoose() {
  const data = [
    {
      heading: "Tailored Roadmaps:",
      para: "Clear steps based on your career goals.",
    },
    {
      heading: "Expert Advice:",
      para: "Insights from seasoned professionals.",
    },
    {
      heading: "Job Opportunities:",
      para: "Access exclusive listings.",
    },
    {
      heading: "Skill Development:",
      para: "Suggestions for upskilling.",
    },
    {
      heading: "One-on-One Sessions:",
      para: "Personalized mentoring.",
    },
  ];

  return (
    <div className="border">
      <div className="flex relative mt-[4rem] ">
        <h3 className="left-3 absolute headings top-[-80px] lg:left-[5rem] lg:top-[-50px]">
          Why Choose Our Career Guidance Program?
        </h3>
        <div className="pl-3 flex flex-col">
          <ul className="list-disc pl-[10px] pt-[20px] pb-[40px] lg:px-[6rem] lg:mt-[2rem]">
            {data.map((e, index) => (
              <div
                key={index}
                className="flex text-[10px] p-[3px] gap-[4px] lg:text-[20px] lg:p-[1rem] py-[6px]"
              >
                <li className="pt-[2px] font-semibold">
                  {e.heading}
                  <span className="font-normal pl-1">{e.para}</span>
                </li>
              </div>
            ))}
          </ul>
        </div>

        <img
          className="h-[6rem] mt-10 lg:h-[22rem] lg:pl-[7rem]"
          src={checking}
          alt="checkingImg"
        />
      </div>
    </div>
  );
}

export default WhyChoose;
