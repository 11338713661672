import blueStar from "../../../assets/imgs/filledGrayStar.svg";
import blurStar from "../../../assets/imgs/unfilledGrayStar.svg";

interface CardProps {
  imgSrc?: string;
  name?: string;
  review?: string;
  stars?: number;
}

function ReviewCard({ stars = 0, review, imgSrc, name }: CardProps) {
  const filledStars = Math.min(stars, 5);
  const totalStars = 5;
  return (
    <div className="relative shadow-2xl h-[11rem] lg:h-[22rem]  w-[7rem] lg:w-[17rem]">
      <div className="bg-[#7E7D7C] absolute w-[25%] h-[1.6%]"></div>
      <div className="p-2 lg:p-[17px]">
        <div className="flex gap-[-10px]">
          {Array.from({ length: totalStars }).map((_, index) => (
            <img
              key={index}
              src={index < filledStars ? blueStar : blurStar}
              alt={index < filledStars ? "Filled star" : "Empty star"}
              className="h-[12px] w-[12px] lg:h-10 lg:w-8"
            />
          ))}
        </div>

        <p className="text-[7px] leading-[10px] mt-4 lg:text-[1.2rem] lg:leading-[30px]">
          {review}
        </p>
      </div>

      <div className="bg-[#7E7D7C] absolute bottom-0 w-full  flex items-center px-2 gap-2">
        <img className="h-[30px]" src={imgSrc} alt="reviewerImg" />
        <p className="text-[8px] text-white pb-2 lg:text-[1.2rem]">{name}</p>
      </div>
    </div>
  );
}

export default ReviewCard;
