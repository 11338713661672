import limitedTimeOffer from "../../../assets/imgs/limitedTimeOffer1.svg";

function LimitedTimeOffer() {
  return (
    <div className="mb-[4rem]">
      <div className="flex lg:justify-center">
        <img
          className="pl-2 lg:h-[12rem]"
          src={limitedTimeOffer}
          alt="limitedTimeOffer"
        />

        <div className="pl-[20px] pr-[10px]">
          <h3 className="headings">Limited-Time Offer – Act Fast!</h3>
          <p className="text-[12px] leading-[20px]  lg:text-[24px] lg:leading-[40px]">
            This exclusive offer is available for the next 48 hours only.
          </p>
          <p className="text-[12px] font-semibold leading-[20px] lg:text-[24px] lg:leading-[40px]">
            Countdown Timer: 47:59:00
          </p>
        </div>
      </div>
      {/* Button */}
      <div className="flex justify-center mt-4">
        <button className="text-[12px] w-[50%] lg:w-[30%] h-[24px] bg-lcsBlue rounded-md text-white shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[45px]">
          Enroll Now
        </button>
      </div>
    </div>
  );
}

export default LimitedTimeOffer;
