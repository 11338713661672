// import blueStar from "../../../../assets/imgs/star_icon.svg";
// import blurStar from "../../../../assets/imgs/blurStar.svg";

interface CardProps {
  imgSrc?: string;
  name?: string;
  review?: string;
  //   stars?: number;
}

function ReviewCard({ imgSrc, name, review }: CardProps) {
  //   const filledStars = Math.min(stars, 5);
  //   const totalStars = 5;
  return (
    <div className="flex flex-col gap-2 items-center pt-1 text-slate-600  border border-lcsBlue  rounded-md relative py-4 bg-[#F5F5F5] lg:mx-[2rem]">
      <div className="absolute top-[-8%] lg:top-[-14%] ">
        <img
          className="h-12 rounded-full lg:h-40 border border-lcsBlue"
          src={imgSrc}
          alt=""
        />
      </div>
      {/* <div className="flex absolute top-[24%] lg:top-[34%]">
        {Array.from({ length: totalStars }).map((_, index) => (
          <img
            key={index}
            src={index < filledStars ? blueStar : blurStar}
            alt={index < filledStars ? "Filled star" : "Empty star"}
            className="h-[10px] w-[10px] lg:h-10 lg:w-8"
          />
        ))}
      </div> */}
      <p className="text-xs text-black pt-[2.1rem] lg:text-[1.3rem]  lg:pt-[8rem]">
        {name}
      </p>
      {/* Add Stars here */}

      <p className="text-[8px] p-1 lg:text-[1rem] mt-[-6px] lg:leading-10 leading-3 text-center">
        {review}
      </p>
    </div>
  );
}

export default ReviewCard;
