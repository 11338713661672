import { IoLogoLinkedin, IoMail } from "react-icons/io5";
import { PiPhoneCallFill } from "react-icons/pi";
import logo from "../assets/imgs/xlogoBlue1.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer bg-[#030A74] h-[33rem] md:h-[26rem]">
      <div className="flex gap-0 justify-center items-center pl-[1rem] py-[2rem] md:py-[3rem] ">
        <img src={logo} alt="footer_img" className="h-[3rem] md:h-[4rem]" />
        <h4 className="text-[#fff] font-bold text-[1rem] md:text-xl pr-1 lg:text-left">
          Join us in shaping the future of Indian professional networking!
        </h4>
      </div>

      <div className="main-footer text-white grid grid-cols-2 md:grid-cols-3 px-[2rem] gap-y-[2rem] md:px-[4rem] lg:px-[8rem]">
        <div className="company">
          <h4>COMPANY</h4>
          <p>
            <Link to="/about-us">About Us</Link>
          </p>
          <p>
            <Link to="#">Career</Link>
          </p>
          <p>
            <Link to="/termsofservice">Terms of Service</Link>
          </p>
          <p>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </p>
        </div>

        <div className="contact">
          <h4>CONTACT</h4>

          <div className="flex items-center gap-1">
            <IoLogoLinkedin />
            <a href="https://www.linkedin.com/company/xampus/?viewAsMember=true">
              <p className="pb-2">LinkedIn</p>
            </a>
          </div>

          <div className="flex items-center gap-1">
            <IoMail />
            <p className="pb-2">info@xampus.in</p>
          </div>

          <div className="flex items-center gap-1">
            <PiPhoneCallFill />
            <p className="pb-2">70209 99022</p>
          </div>
        </div>

        <div className="location">
          <h4>OUR LOCATION</h4>
          <p>
            Plot No. 9, survey No. 48/2 , Ambegaon Khurd, Haveli Dist. Pune
            411046
          </p>
        </div>
      </div>

      <div className="copyright text-center md:pr-[5rem] mt-[1rem] md:mt-[2rem]">
        <p className="text-white font-semibold">
          ©2024 Xampus Tech Pvt Ltd. All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
