import React from "react";
import ReviewCard from "./Cards/ReviewCard";
import pic1 from "../../../assets/imgs/ayushvsSqaure.jpeg";
import pic2 from "../../../assets/imgs/sandhya1.jpg";
import pic3 from "../../../assets/imgs/arthavSqaureImg.jpeg";

function Review() {
  return (
    <div className=" flex flex-col items-center lg:py-[4rem]">
      <h3 className="headings mt-6 pb-8 lg:pb-[100px]">
        Hear From Our Happy Clients!
      </h3>
      <div className="flex gap-2 justify-center px-2">
        <ReviewCard
          //   stars={5}
          imgSrc={pic1}
          name="AYUSH S"
          review={` "The personalized guidance I received helped me craft a career plan that led to multiple job offers. The mentors were incredibly supportive and insightful!"`}
        />
        <ReviewCard
          //   stars={4}
          imgSrc={pic2}
          name="SANDHYA M"
          review={`"This program completely transformed my career outlook. The tailored roadmaps and expert advice gave me the confidence to pursue my goals."`}
        />
        <ReviewCard
          //   stars={5}
          imgSrc={pic3}
          name="ATHARAV D"
          review={`"Enrolling in this program was the best decision I made for my career. The job placement assistance and upskilling recommendations were game-changers!"`}
        />
      </div>
    </div>
  );
}

export default Review;
