import React from "react";

function LimitedTimeOffer() {
  return (
    <>
      <hr className="border-[#C4C4C4]" />
      <div className="text-center py-5">
        <h3 className="headings m-[8px]">"Limited-Time Offer – Act Fast!"</h3>
        <h3 className="text-[18px] font-normal lg:text-[30px] m-[8px] lg:pt-[1rem]">
          This exclusive offer is available for the next 48 hours only.
        </h3>
        <h3 className="text-[18px] font-normal lg:text-[30px] m-[8px] pb-3 lg:pb-[30px] lg:pt-[1rem]">
          Countdown Timer: 47:59:00
        </h3>
        <div className="flex justify-center ">
          <button className="text-[12px] w-[50%] lg:w-[30%] h-[24px] bg-lcsBlue rounded-md text-white shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[45px]">
            Enroll Now
          </button>
        </div>
      </div>
      <hr className="border-[#C4C4C4] mt-4" />
    </>
  );
}

export default LimitedTimeOffer;
