import React from "react";

function LimitedTimeOffer() {
  return (
    <div className="bg-lcsBlue text-white text-center py-5">
      <h3 className="headings m-[8px]">"Limited-Time Offer – Act Fast!"</h3>
      <h3 className="headings m-[8px] lg:pt-[1rem]">
        This exclusive offer is available for the next 48 hours only.
      </h3>
      <h3 className="headings m-[8px] pb-3 lg:pb-[30px] lg:pt-[1rem]">
        Countdown Timer: 47:59:00
      </h3>
      <div className="flex justify-center">
        <button className="text-[12px] w-[50%] lg:w-[30%] h-[24px] bg-white rounded-md text-lcsBlue shadow-[0px_12px_6px_rgba(0,0,0,0.3)] lg:text-[18px] lg:h-[40px]">
          Enroll Now
        </button>
      </div>
    </div>
  );
}

export default LimitedTimeOffer;
